<template>
  <b-card
    :header="$t('addons.create')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <AddGenericCategoryModal
      :temp-sub-category="tempSubCategory"
      :temp-generic.sync="tempGeneric"
      :add-generic-to-sub-category="addGenericToSubCategory"
      :gift-type="giftTypeList"
    />
    <div class="">
      <b-button
        size="sm"
        class="ml-auto"
        variant="outline-success"
        style="display: block"
        @click="handleGoBackButton"
      >
        <feather-icon icon="ArrowLeftCircleIcon" class="mr-25" />
        {{ $t("global.previous") }}
      </b-button>
    </div>
    <b-row>
      <b-col md="6" class="padding-zero">
        <b-form-group
          :label="$t('addons.categoryName')"
          label-for="category-name"
        >
          <div class="d-flex">
            <b-form-input
              id="category-name-geo"
              v-model="category.productCategoryNameGeo"
              :readonly="disableCatNames || !!selectedCategoryId"
              type="text"
              class="mr-1"
              :placeholder="$t('global.nameGeo')"
            />
            <b-form-input
              id="category-name-eng"
              v-model="category.productCategoryNameEng"
              :readonly="disableCatNames || !!selectedCategoryId"
              type="text"
              :placeholder="$t('global.nameEng')"
            />
          </div>
        </b-form-group>
        <div class="d-flex align-items-center justify-content-center mb-1">
          <div style="width: 10%; height: 1px; background: gray"></div>
          <span class="mx-2">{{ $t("addons.chooseFromExisting") }}</span>
          <div style="width: 10%; height: 1px; background: gray"></div>
        </div>
        <div class="d-flex align-items-center mb-2">
          <b-form-select
            v-model="selectedCategoryId"
            :disabled="
              !!category.productCategoryNameEng ||
              !!category.productCategoryNameGeo
            "
            :options="categories && categories.data.productCategories"
            :text-field="
              $i18n.locale === 'ka'
                ? 'productCategoryNameGeo'
                : 'productCategoryNameEng'
            "
            value-field="productCategoryId"
          />
          <div
            style="min-width: 30px"
            class="d-flex justify-content-center cursor-pointer"
          >
            <feather-icon
              v-if="selectedCategoryId"
              icon="XIcon"
              size="20"
              @click="selectedCategoryId = null"
            />
          </div>
        </div>
        <!--  -->
        <!-- hide this is category is selected and category.subcategories.length is exactly 1 -->
        <div v-if="didsableAddingMoreSubCategories" class="category-container">
          <p class="mt-2">{{ $t("addons.addSubCategories") }}:</p>
          <label>{{ $t("addons.subCategoryName") }}</label>
          <b-form-group abel-for="sub-category-name">
            <div class="d-flex">
              <b-form-input
                v-model="subCategoryNameGeo"
                :placeholder="$t('global.nameGeo')"
                label="Sub Category"
                :disabled="
                  !category.productCategoryNameEng && !selectedCategoryId
                "
                label-for="Sub Category"
                class="mb-2 mr-1"
              />
              <b-form-input
                v-model="subCategoryNameEng"
                :placeholder="$t('global.nameEng')"
                label="Sub Category"
                :disabled="
                  !category.productCategoryNameEng && !selectedCategoryId
                "
                label-for="Sub Category"
                class="mb-2"
              />
            </div>
            <div class="service-type-button">
              <label>{{ $t("addons.selectServiceType") }}</label>
              <b-form-select
                v-model="selectedServiceType"
                :options="serviceTypeList"
              />
            </div>
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="sub-category-button mb-2"
            variant="primary"
            :disabled="!selectedServiceType"
            @click="addSubCategoryName"
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>{{ $t("global.add") }}</span>
          </b-button>
        </div>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="loading"
          variant="primary"
          @click="submitCategory"
        >
          <span>Save</span>
          <feather-icon v-if="loading" icon="LoaderIcon" class="mr-25 ml-1 rotate" />
        </b-button>
      </b-col>
      <b-col md="6">
        <p>{{ $t("addons.subCategories") }}</p>

        <div
          v-for="(subCategory, index) in category.subCategories"
          :key="subCategory.productSubCategoryNameEng + Math.random()"
          class="p-1 rounded border mb-2"
        >
          <div class="d-flex">
            <div>
              <p>
                {{ $t("global.name") }}:
                <b>{{
                  `${subCategory.productSubCategoryNameGeo}  / ${subCategory.productSubCategoryNameEng}`
                }}</b>
              </p>
              <p>
                {{ $t("addons.serviceType") }}:
                <b>{{
                  serviceTypeList.find(
                    (e) => e.value === subCategory.serviceType
                  ).text
                }}</b>
              </p>
            </div>
            <div class="d-flex flex-column ml-auto align-items-end">
              <b-button
                size="sm"
                variant="outline-success"
                pill
                class="mb-1"
                @click="openGenericModal(subCategory)"
              >
                {{ $t("addons.addNewGeneric") }}
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                class="ml-1"
                @click="category.subCategories.splice(index, 1)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </div>
          <label v-if="subCategory.genericCategories.length">
            {{ $t("addons.genericOfthisSub") }}:
          </label>
          <div
            v-for="(genericCategory, index) in subCategory.genericCategories"
            :key="genericCategory.productGenericCategoryNameGeo + Math.random()"
            class="p-1 mb-1 border rounded-sm d-flex justify-content-between"
          >
            <div>
              <div>
                {{ $t("global.name") }}:
                <b>{{
                  `${genericCategory.productGenericCategoryNameGeo}  / ${genericCategory.productGenericCategoryNameEng}`
                }}</b>
              </div>
              <div>
                {{ $t("addons.giftType") }}:
                {{
                  giftTypeList.find((e) => e.value === genericCategory.giftType)
                    .text
                }}
              </div>
              <div>
                {{ $t("addons.percentage") }}:
                <b>
                  {{ genericCategory.emotyCommissionFeePercent }}
                </b>
              </div>
            </div>
            <div>
              <b-button
                size="sm"
                variant="danger"
                @click="subCategory.genericCategories.splice(index, 1)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BCol,
  BCard,
  BButton,
  BRow,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import AddGenericCategoryModal from "./components/AddGenericCategoryModal.vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    AddGenericCategoryModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      disableCatNames: false,
      tempSubCategory: null,
      selectedServiceType: null,
      commisions: [],
      giftTypes: [],
      tempGeneric: {
        productGenericCategoryNameGeo: "",
        productGenericCategoryNameEng: "",
        giftType: null,
        emotyCommissionFeePercent: null,
      },
      subCategoryNameGeo: "",
      subCategoryNameEng: "",
      category: {
        productCategoryNameEng: "",
        productCategoryNameGeo: "",
        subCategories: [],
      },
      addingSubCategories: false,
      loading: false,
      selectedCategoryId: null,
    };
  },
  computed: {
    ...mapState("parametersModule", ["categories"]),
    didsableAddingMoreSubCategories() {
      if (this.selectedCategoryId && this.category.subCategories.length > 0) {
        return false;
      }
      return true;
    },
    serviceTypeList() {
      return [
        {
          value: 1,
          text: this.$i18n.t("global.experiential"),
        },
        {
          value: 2,
          text: this.$i18n.t("global.material"),
        },
        {
          value: 3,
          text: this.$i18n.t("global.digital"),
        },
        {
          value: 4,
          text: this.$i18n.t("global.card"),
        },
      ];
    },
    giftTypeList() {
      return [
        {
          value: 1,
          text: this.$i18n.t("global.both"),
        },
        {
          value: 2,
          text: this.$i18n.t("global.daily"),
        },
        {
          value: 3,
          text: this.$i18n.t("global.holiday"),
        },
      ];
    },
  },
  mounted() {
    this.getCategories({ take: 100, skip: 0 });
  },
  methods: {
    ...mapActions("parametersModule", [
      "createCategory",
      "getCategories",
      "addSubCategoriesToCategory",
    ]),

    handleGoBackButton() {
      this.$bvModal
        .msgBoxConfirm("Are you sure?")
        .then((result) => {
          if (result) this.$router.push("/categories");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addGenericToSubCategory(subCategory) {
      // PUSH THIS TO existing sub category
      const subCatIndex = this.category.subCategories.indexOf(subCategory);

      this.category.subCategories[subCatIndex].genericCategories.push(
        this.tempGeneric
      );
      // reset the tempgeneric and tempsubcategory
      // this.tempSubCategory = null;
      this.tempGeneric = {
        productGenericCategoryNameGeo: "",
        productGenericCategoryNameEng: "",
        giftType: null,
        emotyCommissionFeePercent: null,
      };

      // this.$bvModal.hide('add-generic-category-modal');
    },
    openGenericModal(subCategory) {
      this.tempSubCategory = subCategory;
      this.$bvModal.show("add-generic-category-modal");
    },
    addSubCategoryName() {
      if (this.subCategoryNameGeo.length) {
        this.category.subCategories.unshift({
          productSubCategoryNameGeo: this.subCategoryNameGeo,
          productSubCategoryNameEng: this.subCategoryNameEng,
          serviceType: this.selectedServiceType,
          genericCategories: [],
        });
        this.disableCatNames = true;
        this.subCategoryNameGeo = "";
        this.subCategoryNameEng = "";
      }
    },
    selectServiceType(item) {
      this.selectedServiceType = item;
    },
    selectGiftType(item) {
      this.selectedGiftType = item;
    },
    async submitCategory() {
      this.loading = true
      if (this.selectedCategoryId) {
        const payload = {
          categoryId: this.selectedCategoryId,
          subCategory: this.category.subCategories[0],
        };
        await this.addSubCategoriesToCategory(payload);
        this.loading = false
        this.$router.push("categories");
      } else {
        await this.createCategory(this.category);
        this.loading = false
        this.$router.push("categories");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.inputs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.b-form-tag .close {
  display: hide !important;
}
.category-container {
  flex-direction: column;
  .sub-category-button {
    width: 100%;
  }
}
.padding-zero {
  padding-left: 0px;
}
.service-type-button {
  width: 100%;
}
.gift-type-select {
  width: 30%;
}
.percent-input {
  width: 25%;
}

.rotate{
  animation: loading 3s linear infinite;
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
